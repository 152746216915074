.card {
    box-shadow: 0 4px 15px 0 rgba(34, 43, 55, 0.75);
}
.imgSlide {
    width: 100%;
    height: 49vh
}

.MainCont {
    width: 100%;
    height: 560vh;
    background-color: black;
    display: flex;
    justify-content: center
}

.chooseYour {
    /* background: url(../Components/Image/bgforTetx.jpg); */
    height: 85vh;
    background: white
}


.hoeMainCont {
    height: 60vh;
    width: 100%;
    background-image: url(../Components/Image/casinoBanner.avif);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.homeMainChild {
    width: 100%;
    height: 60vh;
    background: rgba(0, 0, 0, 0.435);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.homeMainChild h2 {
    font-size: 25px;
    color: white;
}
.homeMainChild p {
    color: white;
}

.welcomeCont {
    background: url(../Components/Image/bg1.webp);
    height: 65vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.welcomeCont2 {
    background: rgb(4 4 4 / 73%);
    height: 65vh;
    width: 100%;
    padding: 0%;
    position: absolute;
    top: 0%;
    left: 0%;
   
}

.bigWIn {
    /* background: url('./Image/bigWin.png'); */
    width: 100%;
    height: 30vh;
    background-repeat: no-repeat;
    background-size: cover
}

.faq {
    height: 70vh;
    width: 100%;
    background: radial-gradient(100% 5568.89% at 0 0, #544bc9 0, #a44bc9 100%), #544bc9;
}
.chooseChild1{
    height:70vh;
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:space-around;
    align-items:center
}
.chooseChild2{
    height:28vh;
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:space-around;
    align-items:center
}
.clientRev{
    background: url(../Components/Image/bgframe.png);
    height: 90vh;
     width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
       display: flex;
        flex-direction: column;
         justify-content: space-around; 
         align-items: center 
}
@media only screen and (max-width: 650px) {
    .chooseYour {
        background: white;
        height: 70vh;
    }

    .MainCont {
        width: 100%;
        height: 450vh;
        background-color: black;
        display: flex;
        justify-content: center
    }

    .hoeMainCont {
        height: 56vh;
        width: 100%;
        /* background:radial-gradient(100% 5568.89% at 0 0, #544bc9 0, #a44bc9 100%), #544bc9; */
        background-image: url(../Components/Image/casinoBanner.avif);
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .homeMainChild {
        width: 100%;
        height: 56vh;
        background: rgba(0, 0, 0, 0.435);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .homeMainChild h2 {
        font-size: 25px;
        color: white;
    }
    .homeMainChild p {
        color: white;
    }
    .welcomeCont {
        background: url(../Components/Image/bg1.webp);
        height: 65vh;
        width: 100%;
        background-repeat: no-repeat;
        /* background-size: cover; */
        display: flex;
        flex-direction: column;
        justify-content: center
    }
    .bigWIn {
        /* background: url('./Image/slots.gif'); */
        width: 100%;
        height: 25vh;
        background-repeat: no-repeat;
        background-size: cover
    }
    .faq {
        height: 64vh;
        width: 100%;
        background: radial-gradient(100% 5568.89% at 0 0, #544bc9 0, #a44bc9 100%), #544bc9;
    }
    .chooseChild1{
        height:60vh;
        width:100%;
        display:flex;
        flex-direction:column;
        justify-content:space-around;
        align-items:center
    }
    .chooseChild2{
        height:27vh;
        width:100%;
        display:flex;
        flex-direction:row;
        justify-content:space-around;
        align-items:center
    }
    .clientRev{
        background: url(../Components/Image/bgframe.png);
        height: 79vh;
         width: 100%;
          background-repeat: no-repeat;
          background-size: cover;
           display: flex;
            flex-direction: column;
             justify-content: space-around; 
             align-items: center 
    }
}