.custom-navbar{
    width: 450px;
    margin: 0 auto; /* Center the navbar */
    background: #f1efef59;
}
.custom-offcanvas {
    width: 100% !important; /* Ensure the off-canvas is the full width of the navbar */
    max-width: 450px; /* Restrict the max width to the navbar's width */
    margin-right: 33%;
  }

  @media only screen and (max-width: 768px){
    .custom-navbar{
       width: 100%;
    }
    
  }
  @media only screen and (max-width: 650px){
    .custom-offcanvas{
      margin-right: 0%;
    }
  }